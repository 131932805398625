import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout2';








const HomePage = (props: any) => {
  const { data } = props;


  const testdata = data.allMarkdownRemark.edges.map(({ node }, index) => (
    <span key={index}>{node.frontmatter.no}{node.frontmatter.title}{node.frontmatter.sdgs_no}
    </span>
    ))




    const csvdata = data.allMarkdownRemark.edges.map(({ node }, index) => (
      <span key={index}>{node.frontmatter.no},{node.frontmatter.title},{node.frontmatter.sdgs_no},{node.frontmatter.sdgs_main},{node.frontmatter.sdgs_subcat},{node.frontmatter.kou_tisou_pt},{node.frontmatter.kou_tisou_chp},{node.frontmatter.kou_tisou_sec},{node.frontmatter.kou_tisou_sbsec},{node.frontmatter.kou_tisou_title},{node.frontmatter.kou_titan_pt},{node.frontmatter.kou_titan_chp},{node.frontmatter.kou_titan_sec},{node.frontmatter.kou_titan_sbsec},{node.frontmatter.kou_titan_title},{node.frontmatter.height_name},{node.frontmatter.height_origin},{node.frontmatter.height_origin_note},{node.frontmatter.height_origin_url},{node.frontmatter.height_origin_url2},{node.frontmatter.color_name},{node.frontmatter.color_origin},{node.frontmatter.color_origin_note},{node.frontmatter.color_origin_url},{node.frontmatter.color_origin_url2},{node.frontmatter.sokuchi},{node.frontmatter.mesh_size},{node.frontmatter.year_display},{node.frontmatter.honbun},{node.frontmatter.chyusyaku},{node.frontmatter.hosoku},{node.frontmatter.tags},{node.frontmatter.kml_file},{node.frontmatter.y202012},{node.frontmatter.y202101},{node.frontmatter.y202102},{node.frontmatter.date},{node.frontmatter.description}\n
      
      </span>
      ))

    const str = data.allMarkdownRemark.edges[0].node.frontmatter.title
    console.log(data.allMarkdownRemark.edges);


  return (
    <Layout3>
        <div style={{margin: "30px"}}>
        <h1>サイトに登録されている全ての情報</h1>
        <h2>csv形式</h2>
        <textarea rows="10" cols="60">
        no,title,sdgs_no,sdgs_main,sdgs_subcat,kou_tisou_pt,kou_tisou_chp,kou_tisou_sec,kou_tisou_sbsec,kou_tisou_title,kou_titan_pt,kou_titan_chp,kou_titan_sec,kou_titan_sbsec,kou_titan_title,height_name,height_origin,height_origin_note,height_origin_url,height_origin_url2,color_name,color_origin,color_origin_note,color_origin_url,color_origin_url2,sokuchi,mesh_size,year_display,honbun,chyusyaku,hosoku,tags,kml_file,y202012,y202101,y202102,date,description

</textarea>



        <h2>表形式</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>sdgs_no</th>
              <th>sdgs_main</th>
              <th>sdgs_subcat</th>
              <th>kou_tisou_pt</th>
              <th>kou_tisou_chp</th>
              <th>kou_tisou_sec</th>
              <th>kou_tisou_sbsec</th>
              <th>kou_tisou_title</th>
              <th>kou_titan_pt</th>
              <th>kou_titan_chp</th>
              <th>kou_titan_sec</th>
              <th>kou_titan_sbsec</th>
              <th>kou_titan_title</th>
              <th>height_name</th>
              <th>height_origin</th>
              <th>height_origin_note</th>
              <th>height_origin_url</th>
              <th>height_origin_url2</th>
              <th>color_name</th>
              <th>color_origin</th>
              <th>color_origin_note</th>
              <th>color_origin_url</th>
              <th>color_origin_url2</th>
              <th>sokuchi</th>
              <th>mesh_size</th>
              <th>year_display</th>
              <th>honbun</th>
              <th>chyusyaku</th>
              <th>hosoku</th>
              <th>tags</th>
              <th>kml_file</th>
              <th>y202012</th>
              <th>y202101</th>
              <th>y202102</th>
              <th>date</th>
              <th>description</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>{node.frontmatter.no}</td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.sdgs_no}</td>
                <td>{node.frontmatter.sdgs_main}</td>
                <td>{node.frontmatter.sdgs_subcat}</td>
                <td>{node.frontmatter.kou_tisou_pt}</td>
                <td>{node.frontmatter.kou_tisou_chp}</td>
                <td>{node.frontmatter.kou_tisou_sec}</td>
                <td>{node.frontmatter.kou_tisou_sbsec}</td>
                <td>{node.frontmatter.kou_tisou_title}</td>
                <td>{node.frontmatter.kou_titan_pt}</td>
                <td>{node.frontmatter.kou_titan_chp}</td>
                <td>{node.frontmatter.kou_titan_sec}</td>
                <td>{node.frontmatter.kou_titan_sbsec}</td>
                <td>{node.frontmatter.kou_titan_title}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.height_origin}</td>
                <td>{node.frontmatter.height_origin_note}</td>
                <td>{node.frontmatter.height_origin_url}</td>
                <td>{node.frontmatter.height_origin_url2}</td>
                <td>{node.frontmatter.color_name}</td>
                <td>{node.frontmatter.color_origin}</td>
                <td>{node.frontmatter.color_origin_note}</td>
                <td>{node.frontmatter.color_origin_url}</td>
                <td>{node.frontmatter.color_origin_url2}</td>
                <td>{node.frontmatter.sokuchi}</td>
                <td>{node.frontmatter.mesh_size}</td>
                <td>{node.frontmatter.year_display}</td>
                <td>{node.frontmatter.honbun}</td>
                <td>{node.frontmatter.chyusyaku}</td>
                <td>{node.frontmatter.hosoku}</td>
                <td>{node.frontmatter.tags}</td>
                <td>{node.frontmatter.kml_file}</td>
                <td>{node.frontmatter.y202012}</td>
                <td>{node.frontmatter.y202101}</td>
                <td>{node.frontmatter.y202102}</td>
                <td>{node.frontmatter.date}</td>
                <td>{node.frontmatter.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout3>
  );
};

export default HomePage;

interface IndexPageProps {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: {
              title: string;
              no: string;
              sdgs_no: string;
              sdgs_main: string;
              sdgs_subcat: string;
              kou_tisou_pt: string;
              kou_tisou_chp: string;
              kou_tisou_sec: string;
              kou_tisou_sbsec: string;
              kou_tisou_title: string;
              kou_titan_pt: string;
              kou_titan_chp: string;
              kou_titan_sec: string;
              kou_titan_sbsec: string;
              kou_titan_title: string;
              height_name: string;
              height_origin: string;
              height_origin_note: string;
              height_origin_url: string;
              height_origin_url2: string;
              color_name: string;
              color_origin: string;
              color_origin_note: string;
              color_origin_url: string;
              color_origin_url2: string;
              sokuchi: string;
              mesh_size: string;
              year_display: string;
              honbun: string;
              chyusyaku: string;
              hosoku: string;
              tags: string;
              kml_file: string;
              y202012: string;
              y202101: string;
              y202102: string;
              date: string;
              description: string;
            }
          }
        }
      ]
    }
  };
}

declare function graphql(x: TemplateStringsArray): any;
export const pageQuery = graphql`
query {
  allMarkdownRemark(sort: {fields: frontmatter___no}) {
    edges {
      node {
        frontmatter {
          title
          no
          sdgs_no
          sdgs_main
          sdgs_subcat
          kou_tisou_pt
          kou_tisou_chp
          kou_tisou_sec
          kou_tisou_sbsec
          kou_tisou_title
          kou_titan_pt
          kou_titan_chp
          kou_titan_sec
          kou_titan_sbsec
          kou_titan_title
          height_name
          height_origin
          height_origin_note
          height_origin_url
          height_origin_url2
          color_name
          color_origin
          color_origin_note
          color_origin_url
          color_origin_url2
          sokuchi
          mesh_size
          year_display
          honbun
          chyusyaku
          hosoku
          tags
          kml_file
          y202012
          y202101
          y202102
          date
          description
        }
      }
    }
  }
}

`;
